@import url(https://fonts.googleapis.com/css?family=Lato|Concert+One|Titillium+Web|DM+Serif+Text|Montserrat|Lobster|Barriecito|Yanone+Kaffeesat|Roboto|Ubuntu&display=swap);
* {
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto", "Open Sans", monospace;
}
*,
.App {
  padding: 0;
}
.App {
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}
.container,
nav {
  width: 100%;
}
@media (min-width: 800px) {
  .container,
  nav {
    width: 800px;
  }
}
#back-to-top {
  position: fixed;
  left: 30px;
  bottom: 30px;
  opacity: 0;
  pointer-events: none;
  cursor: pointer;
  transition: opacity 0.1s ease-in-out;
}
#back-to-top > * {
  font-size: 50px;
  fill: #f46d3e;
}
#back-to-top > :hover {
  transition: all 0.15s linear;
  opacity: 0.8;
}
nav {
  position: absolute;
  align-items: flex-start;
  height: 440px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 40px;
}
nav,
nav ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
nav ul {
  align-items: center;
  list-style-type: none;
  width: 80%;
  pointer-events: none;
  z-index: 11;
  transform: translateY(30px);
}
nav ul li {
  position: absolute;
  opacity: 0;
}
.nav-underline {
  height: 2px;
  width: 100%;
  background-color: #4d4d4d;
  transform: scaleX(0);
  transition: transform 0.2s ease;
}
nav ul li a:hover + .nav-underline {
  transform: scaleX(1);
}
nav ul li a {
  color: #141414;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  font-size: 20px;
  font-family: "Lobster", "Titillium Web";
}
nav ul li a:focus {
  outline: none;
}
.nav-bg {
  width: 400px;
  height: 400px;
  border-radius: 100%;
  position: absolute;
  pointer-events: none;
  transform: translateY(-205px);
  z-index: 10;
}
.home-button {
  position: absolute;
  z-index: 11;
}
.home-button h1 {
  font-family: "Lobster";
  color: #f46d3e;
  transform: scale(1.4);
  transition: all 0.3s ease;
}
.home-button > svg {
  transform: scale(1);
  transition: all 0.1s ease;
}
.home-button .reminder-cont {
  position: absolute;
  left: 45px;
  top: 50px;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  width: 150px;
  align-items: flex-end;
  z-index: -2;
}
.home-button .reminder-cont .click-me-reminder {
  padding-bottom: 6px;
  color: #e96435;
  font-family: "Lobster";
  font-size: 20px;
  padding-left: 7px;
}
.screen-fade {
  background: linear-gradient(#fff, hsla(0, 0%, 100%, 0.3));
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease;
}
.home {
  min-height: 100vh;
  padding-top: 120px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 40px;
  flex-direction: column;
}
#home-background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
}
.picture {
  height: 225px;
  padding: 10px;
}
.picture img {
  height: 100%;
  border-radius: 100%;
  user-select: none;
}
.heading {
  font-size: 3.5em;
  color: #252525;
  font-family: "Titillium Web", "DM Serif Text", "Roboto";
  line-height: 1em;
  min-height: 70px;
}
.heading,
.subheading {
  text-align: center;
  user-select: none;
  padding-left: 20px;
  padding-right: 20px;
}
.subheading {
  font-size: 1.7em;
  color: #696969;
  font-family: "Titillium Web", "Roboto Light";
}
#nav-icons {
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-self: center;
  align-items: center;
  height: 50px;
}
.icon-trigger {
  cursor: pointer;
  height: 50px;
  width: 50px;
  z-index: 2 !important;
  position: absolute;
}
.color-change {
  fill: #252525;
}
.icon-trigger:hover .color-change {
  fill: #747474;
  transition: all 0.1s ease;
}
@media (min-width: 500px) {
  .home {
    height: 100vh;
    padding-top: 120px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 60px;
    flex-direction: column;
  }
  .picture img {
    height: 100%;
    border-radius: 100%;
    user-select: none;
  }
  .heading {
    font-size: 6em;
    color: #252525;
  }
  .heading,
  .subheading {
    text-align: center;
    user-select: none;
  }
  .subheading {
    font-size: 1.6em;
    color: #696969;
  }
}
@media (min-width: 800px) {
  .home {
    height: 100vh;
    padding-top: 120px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 60px;
    flex-direction: column;
  }
  .picture img {
    height: 100%;
    border-radius: 100%;
    user-select: none;
  }
  .heading {
    font-size: 8em;
    color: #252525;
  }
  .heading,
  .subheading {
    text-align: center;
    user-select: none;
  }
  .subheading {
    font-size: 2.4em;
    color: #696969;
  }
}
@media (max-height: 600px) {
  .home {
    min-height: 100vh;
    padding-top: 120px;
    padding-bottom: 10px;
    flex-direction: column;
  }
  #home-background {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
  }
  .picture {
    height: 225px;
    padding: 10px;
  }
  .picture img {
    height: 100%;
    border-radius: 100%;
    user-select: none;
  }
  .heading {
    font-size: 3.5em;
    color: #252525;
  }
  .heading,
  .subheading {
    text-align: center;
    user-select: none;
    padding-left: 20px;
    padding-right: 20px;
  }
  .subheading {
    font-size: 1.7em;
    color: #696969;
  }
  #nav-icons {
    width: 300px;
    display: flex;
    justify-content: space-between;
    align-self: center;
    align-items: center;
    height: 50px;
  }
  .icon-trigger {
    cursor: pointer;
    height: 50px;
    width: 50px;
    z-index: 2 !important;
    position: absolute;
  }
  .color-change {
    fill: #252525;
  }
  .icon-trigger:hover .color-change {
    fill: #747474;
    transition: all 0.1s ease;
  }
}
.about {
  min-height: 100vh;
  padding-top: 130px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 20px;
}
#about-background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
}
.about > * {
  text-align: center;
  margin-bottom: 24px;
}
.about-heading {
  font-size: 4em;
  color: #222;
  font-family: "Barriecito";
}
.back-to-home {
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  padding: 5px;
}
.back-to-home * {
  pointer-events: none;
}
.back-to-home div {
  transition: color 0.1s ease-in-out;
  position: absolute;
  margin-top: 8px;
  font-size: 26px;
  color: #666;
  left: 24px;
}
.about > p,
.back-to-home span {
  font-size: 16px;
  line-height: 19px;
  padding-left: 60px;
  padding-right: 60px;
  transition: color 0.1s ease-in-out;
}
.about > p,
.about a,
.back-to-home span {
  color: #666;
  font-family: "Concert One";
  text-decoration: none;
}
.back-to-home:hover > div,
.back-to-home:hover > span {
  color: #f46d3e;
}
@media (min-width: 800px) {
  .about {
    height: 100vh;
    padding-top: 130px;
    display: flex;
    align-items: center;
    padding-bottom: 40px;
    flex-direction: column;
  }
  #about-background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
  }
  .about > * {
    text-align: center;
    margin-bottom: 40px;
  }
  .about-heading {
    font-size: 5em;
    color: #222;
    font-family: "Barriecito";
  }
  .about > p,
  .back-to-home span {
    font-size: 20px;
    line-height: 22px;
    color: #666;
    padding-left: 60px;
    padding-right: 60px;
    font-family: "Concert One";
  }
}
.web-dev {
  min-height: 100vh;
  padding-top: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#web-dev-background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
}
.each-project {
  width: 100%;
  position: relative;
  line-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.each-project > .ep-in {
  width: 0;
  pointer-events: none;
  position: relative;
}
.each-image {
  width: 100%;
}
.hover-details {
  position: absolute;
  background-color: #4b4b4b;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  justify-content: center;
  align-items: center;
}
.project-title {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
  font-family: "Ubuntu";
  line-height: 40px;
  text-align: center;
}
.project-description {
  color: #999;
  font-size: 16px;
  font-weight: lighter;
  margin-bottom: 40px;
  padding-right: 100px;
  line-height: 20px;
  display: none;
}
.project-description a {
  text-decoration: underline !important;
}
.project-button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all 0.15s linear;
  text-align: center;
  white-space: nowrap;
  text-decoration: none !important;
  text-transform: none;
  text-transform: capitalize;
  color: #fff;
  border: 0;
  border-radius: 4px;
  width: 160px;
  height: 40px;
  box-shadow: 2px 5px 10px rgba(22, 22, 22, 0.2);
  background: #f46d3e;
}
.project-button:hover {
  transition: all 0.15s linear;
  opacity: 0.85;
}
.hover-details a {
  color: #fff;
  text-decoration: none;
  position: relative;
}
.hover-details:hover {
  opacity: 1;
}
#last-project {
  height: 150px;
}
#last-project a {
  text-decoration: none;
  color: #666;
  font-family: "Concert One";
}
@media (min-width: 400px) {
  .hover-details {
    padding-left: 40px;
    padding-right: 20px;
    align-items: flex-start;
  }
  .project-title {
    color: #fff;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 15px;
    font-family: "Ubuntu";
    line-height: 30px;
    text-align: start;
  }
  .project-description {
    color: #ebebeb;
    font-size: 10px;
    font-weight: lighter;
    margin-bottom: 20px;
    padding-right: 20px;
    line-height: 13px;
    display: block;
  }
  .project-button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: all 0.15s linear;
    text-align: center;
    white-space: nowrap;
    text-decoration: none !important;
    text-transform: none;
    text-transform: capitalize;
    color: #fff;
    border: 0;
    border-radius: 4px;
    font-size: 13px;
    width: 130px;
    height: 30px;
    background: #f46d3e;
  }
}
@media (min-width: 540px) {
  .hover-details {
    padding-left: 40px;
    padding-right: 40px;
    justify-content: center;
    align-items: flex-start;
  }
  .project-title {
    color: #fff;
    font-size: 44px;
    font-weight: 700;
    margin-bottom: 25px;
    font-family: "Ubuntu";
    line-height: 40px;
  }
  .project-description {
    color: #ebebeb;
    font-size: 15px;
    font-weight: lighter;
    margin-bottom: 30px;
    padding-right: 0;
    line-height: 20px;
    display: block;
  }
  .project-button {
    font-size: 13px;
    width: 130px;
    height: 30px;
  }
}
@media (min-width: 690px) {
  .hover-details {
    padding-left: 100px;
    align-items: flex-start;
  }
  .project-title {
    color: #fff;
    font-size: 54px;
    font-weight: 700;
    margin-bottom: 30px;
    font-family: "Ubuntu";
    line-height: 60px;
  }
  .project-description {
    color: #ebebeb;
    font-size: 16px;
    font-weight: lighter;
    margin-bottom: 40px;
    padding-right: 100px;
    line-height: 20px;
    display: block;
  }
  .project-button {
    font-size: 16px;
    width: 160px;
    height: 40px;
  }
}
#web-dev-header {
  -webkit-animation: text-fill 0.3s ease 1.6s forwards;
  animation: text-fill 0.3s ease 1.6s forwards;
  width: 100%;
  margin-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
}
#web-dev-header path {
  overflow: visible;
}
#web-dev-header path:first-child {
  stroke-dasharray: 400.5714111328125px;
  stroke-dashoffset: 400.5714111328125px;
  -webkit-animation: line-anim 0.3s ease forwards;
  animation: line-anim 0.3s ease forwards;
}
#web-dev-header path:nth-child(2) {
  stroke-dasharray: 257.6293029785156px;
  stroke-dashoffset: 257.6293029785156px;
  -webkit-animation: line-anim 0.3s ease 0.1s forwards;
  animation: line-anim 0.3s ease 0.1s forwards;
}
#web-dev-header path:nth-child(3) {
  stroke-dasharray: 281.0011901855469px;
  stroke-dashoffset: 281.0011901855469px;
  -webkit-animation: line-anim 0.3s ease 0.2s forwards;
  animation: line-anim 0.3s ease 0.2s forwards;
}
#web-dev-header path:nth-child(4) {
  stroke-dasharray: 285.78863525390625px;
  stroke-dashoffset: 285.78863525390625px;
  -webkit-animation: line-anim 0.3s ease 0.3s forwards;
  animation: line-anim 0.3s ease 0.3s forwards;
}
#web-dev-header path:nth-child(5) {
  stroke-dasharray: 257.63116455078125px;
  stroke-dashoffset: 257.63116455078125px;
  -webkit-animation: line-anim 0.3s ease 0.4s forwards;
  animation: line-anim 0.3s ease 0.4s forwards;
}
#web-dev-header path:nth-child(6) {
  stroke-dasharray: 177.6242218017578px;
  stroke-dashoffset: 177.6242218017578px;
  -webkit-animation: line-anim 0.3s ease 0.5s forwards;
  animation: line-anim 0.3s ease 0.5s forwards;
}
#web-dev-header path:nth-child(7) {
  stroke-dasharray: 257.631103515625px;
  stroke-dashoffset: 257.631103515625px;
  -webkit-animation: line-anim 0.3s ease 0.6s forwards;
  animation: line-anim 0.3s ease 0.6s forwards;
}
#web-dev-header path:nth-child(8) {
  stroke-dasharray: 119.6640625px;
  stroke-dashoffset: 119.6640625px;
  -webkit-animation: line-anim 0.3s ease 0.7s forwards;
  animation: line-anim 0.3s ease 0.7s forwards;
}
#web-dev-header path:nth-child(9) {
  stroke-dasharray: 216.88490295410156px;
  stroke-dashoffset: 216.88490295410156px;
  -webkit-animation: line-anim 0.3s ease 0.8s forwards;
  animation: line-anim 0.3s ease 0.8s forwards;
}
#web-dev-header path:nth-child(10) {
  stroke-dasharray: 290.62628173828125px;
  stroke-dashoffset: 290.62628173828125px;
  -webkit-animation: line-anim 0.3s ease 0.9s forwards;
  animation: line-anim 0.3s ease 0.9s forwards;
}
#web-dev-header path:nth-child(11) {
  stroke-dasharray: 334.39312744140625px;
  stroke-dashoffset: 334.39312744140625px;
  -webkit-animation: line-anim 0.3s ease 1s forwards;
  animation: line-anim 0.3s ease 1s forwards;
}
#web-dev-header path:nth-child(12) {
  stroke-dasharray: 257.631103515625px;
  stroke-dashoffset: 257.631103515625px;
  -webkit-animation: line-anim 0.3s ease 1.1s forwards;
  animation: line-anim 0.3s ease 1.1s forwards;
}
#web-dev-header path:nth-child(13) {
  stroke-dasharray: 211.2498321533203px;
  stroke-dashoffset: 211.2498321533203px;
  -webkit-animation: line-anim 0.3s ease 1.2s forwards;
  animation: line-anim 0.3s ease 1.2s forwards;
}
#web-dev-header path:nth-child(14) {
  stroke-dasharray: 157.47454833984375px;
  stroke-dashoffset: 157.47454833984375px;
  -webkit-animation: line-anim 0.3s ease 1.3s forwards;
  animation: line-anim 0.3s ease 1.3s forwards;
}
@-webkit-keyframes line-anim {
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes line-anim {
  to {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes text-fill {
  0% {
    fill: transparent;
  }
  to {
    fill: #2d2d2d;
  }
}
@keyframes text-fill {
  0% {
    fill: transparent;
  }
  to {
    fill: #2d2d2d;
  }
}
.design {
  min-height: 100vh;
  padding-top: 120px;
}
#design-background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
}
.design * {
  user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.design-header {
  width: 100vw;
}
.design-header,
.design-header-overflow {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 152px;
}
.design-header-overflow {
  position: absolute;
  overflow: hidden;
  width: 290px;
}
.first-move {
  z-index: 5;
}
.design-mid {
  z-index: 2;
}
.dark-design {
  z-index: 1;
}
.design-absolute {
  position: absolute;
}
.grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 0;
  grid-row-gap: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.grid > .gridbox {
  cursor: pointer;
  transition: all 0.2s ease;
  width: 100%;
  height: 100%;
}
.thumbnail-wrap {
  width: 100%;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.thumbnail-wrap > img {
  width: 100%;
  border: 1px solid #dadada;
  position: relative;
  transition: all 0.2s ease;
  opacity: 0;
  transform: translateY(5px) scale(1);
}
.thumbnail-wrap > img:hover {
  z-index: 1;
  box-shadow: none;
  transform: scale(1);
}
.fullscreen {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  opacity: 0;
}
.big-image-wrap,
.fullscreen {
  transition: all 0.24s ease-in-out;
}
.big-image-wrap {
  position: relative;
  width: 30vw;
  height: 30vh;
  z-index: 1;
}
.big-image-wrap > img {
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.hide-img {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  z-index: 101;
}
.design-footer {
  height: 30px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.design-footer a > * {
  transition: color 0.3s ease;
}
.design-footer:hover a > * {
  color: #f46d3e;
}
.design-footer a {
  line-height: 18px;
  color: #666;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.view-my-design {
  font-size: 16px;
  font-family: "Concert One";
  margin-right: 5px;
  margin-bottom: 2px;
}
.dribble-icon {
  font-size: 30px;
  color: #666;
  cursor: pointer;
}
@media (min-width: 480px) {
  .design-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 152px;
  }
  .grid {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
  }
  .thumbnail-wrap > img:hover {
    z-index: 1;
    box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.25);
    transform: translateY(0) scale(1.1) !important;
  }
}
@media (min-width: 800px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
  .grid > .gridbox {
    cursor: pointer;
    transition: all 0.2s ease;
    width: 100%;
    height: 195px;
  }
}
.contact {
  min-height: 100vh;
  padding-top: 120px;
  z-index: 1;
  padding-bottom: 30px;
}
.contact * {
  font-family: "Titillium Web";
}
#contact-background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
}
.contact-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100px;
  padding-bottom: 5px;
}
.contact-header h1 {
  font-size: 50px;
}
.contact > form {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.form-label {
  color: grey;
  padding-bottom: 4px;
  text-transform: capitalize;
}
.input-container {
  width: 400px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}
.input-container > input {
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  border: 1px solid #e2e2e2;
  padding-left: 6px;
  color: #292929;
  box-shadow: 0 0 10px rgba(22, 22, 22, 0.05);
}
.input-container > input::-webkit-input-placeholder {
  opacity: 0.2;
}
.input-container > input:-ms-input-placeholder {
  opacity: 0.2;
}
.input-container > input::-ms-input-placeholder {
  opacity: 0.2;
}
.input-container > input::placeholder {
  opacity: 0.2;
}
.form-error {
  position: absolute;
  bottom: -24px;
  height: 24px;
  width: 100%;
  font-size: 14px;
  padding-top: 2px;
  color: #f43e3e;
  display: none;
}
#message-box {
  resize: none;
  overflow-y: scroll;
  min-height: 200px;
  border: 1px solid #e2e2e2;
  color: #292929;
  font-size: 16px;
  padding: 6px 6px 30px;
}
#message-box::-webkit-input-placeholder {
  font-size: 20px;
  opacity: 0.2;
  padding-left: 2px;
}
#message-box:-ms-input-placeholder {
  font-size: 20px;
  opacity: 0.2;
  padding-left: 2px;
}
#message-box::-ms-input-placeholder {
  font-size: 20px;
  opacity: 0.2;
  padding-left: 2px;
}
#message-box::placeholder {
  font-size: 20px;
  opacity: 0.2;
  padding-left: 2px;
}
#message-box:invalid {
  border: none;
  outline: none;
}
.characters {
  position: absolute;
  bottom: 6px;
  left: 12px;
  color: #a0a0a0;
  background-color: #fff;
}
.contact-form-button {
  width: 400px;
  height: 40px;
  box-shadow: 2px 5px 10px rgba(22, 22, 22, 0.4);
  background: #f46d3e;
  color: #fff;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all 0.15s linear;
  text-align: center;
  white-space: nowrap;
  text-transform: capitalize;
  transform: translateX(-100px);
  opacity: 0;
  font-size: 18px;
}
.contact-form-button:hover {
  transition: all 0.15s linear;
  opacity: 0.85 !important;
}
.contact-popups {
  z-index: 1;
  position: fixed;
  width: 800px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  color: #fff;
  font-size: 20px;
  font-weight: 300;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 5px 5px 0 0;
  opacity: 1;
  bottom: 0;
  transform: translateY(60px);
  transition: all 0.4s ease-in-out;
}
.close-btn {
  cursor: pointer;
  user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.contact-error {
  background-color: #ed4c4c;
}
.contact-error a {
  color: #fff;
  text-decoration: none;
  font-weight: 1000;
}
.contact-success {
  background-color: #1eca89;
}
@media (max-width: 800px) {
  .contact-form-button,
  .input-container {
    width: 90%;
  }
  .contact-popups {
    width: 100%;
    border-radius: 0;
    font-size: 16px;
  }
}
/*# sourceMappingURL=main.ad59e991.chunk.css.map */
